<template>
  <div>

    <nav v-if="!value" aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a href="#">Dashboard</a></li>
        <li class="breadcrumb-item" aria-current="page"><a href="#">Vendor</a></li>
        <li class="breadcrumb-item active" aria-current="page">Vendor Details</li>
      </ol>
    </nav>

    <div class="row card-stats" v-if="statsCards" style="width: 100%">
      <div class="col" v-for="card in statsCards" :key="card.subTitle">
        <stats-card
          :title="String(card.title)"
          :sub-title="card.subTitle"
          :type="card.type"
          :icon="card.icon"
        ></stats-card>
      </div>
    </div>
    <form>
      <card>
        <div class="form-row">
          <div class="col-md-8">
            <div class="form-row">
              <div class="col-md-5 has-label">
                <label> Vendor Name </label>
                <div class="md-0 detail-text"> {{ vendor.name }} </div>
              </div>
              <div class="col-md-3 has-label">
                <label> Region </label>
                <div class="md-0 detail-text"> {{ vendor.region }} </div>
              </div>
              <div class="col-md-4 has-label">
                <label> Parcel </label>
                <div class="md-0 detail-text"> {{ vendor.parcel }} </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-5 has-label">
                <label> Affiliate Name </label>
                <div class="md-0 detail-text cap"> {{ mapName(vendor.aff_name) }} </div>
              </div>
              <ValidationProvider
                class="col-md-2"
                name="percentage"
                :rules="{required: true, regex: /^[1-9]{0,1}[0-9]$|^(100)$/}"
                v-slot="{ passed, failed, errors }">
                <base-input :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]" v-model="vendor.aff_cut" 
                  label="Affiliate %" placeholder="0"/>
              </ValidationProvider>
              <div class="col-md-4 ml-auto has-label">
                <label> Last Online </label>
                <div class="md-0 detail-text"> {{ new Date(vendor.seen*1000).toLocaleString() }} </div>
              </div>
            </div>
            <br />
            <div class="form-row">
              <div class="col-md-5">
                <base-input label="Item for sale">
                  <el-autocomplete
                    v-model="sale_item"
                    :fetch-suggestions="fetchSearch"
                    placeholder="Item for sale"
                    @select="handleSelect"
                  ></el-autocomplete>
                </base-input>
              </div>
              <ValidationProvider
                class="col-md-2"
                name="price"
                :rules="{required: true, regex: /^[0-9]{1,4}$/}"
                v-slot="{ passed, failed, errors }">
                <base-input :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]" v-model="vendor.price" 
                  label="Price" placeholder="Price"/>
              </ValidationProvider>
              <ValidationProvider
                class="col-md-2"
                name="group price"
                :rules="{required: true, regex: /^[0-9]{1,4}$/}"
                v-slot="{ passed, failed, errors }">
                <base-input :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]" v-model="vendor.group_price" 
                  label="Group Price" placeholder="Group Price"/>
              </ValidationProvider>
              <div class="col-md-2 has-label">
                <label> Event Mode </label><br />
                <base-switch v-model="vendor.serverless" class="detail-text" on-text="ON" off-text="OFF"></base-switch>
              </div>
            </div>
          </div>
          <div class="col-md-4 ml-auto">
            <ValidationProvider
              name="texture uuid"
              :rules="{regex: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i}"
              v-slot="{ passed, failed, errors }">
              <base-input :error="errors[0]" :class="[{ 'has-success': passed }, { 'has-danger': failed }]" v-model="vendor.texture" 
                label="Texture UUID" placeholder=""/>
            </ValidationProvider>
            <img slot="image" class="card-img-top" :src="this.texture_url">
          </div>
        </div>
        <base-button type="primary" @click.native="runClick()">Save</base-button>
      </card>
    </form>
  </div>
</template>

<script>
  import StatsCard from 'src/components/Cards/StatsCard';
  import {Select, Option, Autocomplete} from 'element-ui';
  import { BaseTable, BaseSwitch } from '@/components';
  import config from '@/config';
  import axios from 'axios';

  import { extend } from "vee-validate";
  import { required, numeric, regex, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("numeric", numeric);
  extend("regex", regex);
  extend("confirmed", confirmed);

  export default {
    components: {
      BaseTable,
      StatsCard,
      BaseSwitch,
      [Select.name]: Select,
      [Option.name]: Option,
      [Autocomplete.name]: Autocomplete
    },
    data() {
      return {
        ready: false,
        search_timer: false, 
        statsCards: false,
        sale_item: '',
        vendor: '',
        id: this.$store.state.modal_data,
        texture_url: false
      }
    },
    props: {
      value: {
        default: false
      }
    },
    mounted() {
    },
    created() {
      if (this.$route.params.id) {
        this.id = this.$route.params.id;
        this.fetchData();
      }
    },
    watch: {
      value: {
        handler(value) {
          if (this.value) {
            this.id = this.$store.state.modal_data;
            this.fetchData();
          } else {
            history.pushState({}, '', window.history.state['prev'])
          }
        }
      },
      'vendor.texture': {
        handler(texture) {
          if(texture == '') {
            texture = 'a1cb7d1a-4eb5-bca4-e16c-1954fcda8426';
          }
          let regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
          if(texture.match(regex)) {
            this.texture_url = `https://picture-service.secondlife.com/${texture}/320x240.jpg`;
          }
        },
        deep: true
      }
    },
    methods: {
      mapName(name) {
        if(!name) return '';
        let parts = name.split(".")
        if (parts[1] == 'resident') parts = [parts[0]]
        return parts.join(' ')
      },

      fetchSearch(search, cb) {
        axios.post(`${this.api_url}/v2/web/account/inventory`, {
          slug: search,
        }, {
          withCredentials: true
        }).then(response => {
          let data = response.data.map(item => {
            return {'value': item.key}
          })
          cb(data);
        })
      },

      fetchData() {
        axios.get(`${this.api_url}/v2/web/account/vendor/details/${this.id}`, {
          withCredentials: true
        }).then(response => {
          this.vendor = response.data;
          this.sale_item = this.vendor.item;
        })

        axios.get(`${this.api_url}/v2/web/account/vendor/stats/${this.id}`, {
          withCredentials: true
        }).then(response => {
          this.statsCards = response.data;
        })
      },

      handleSelect(item) {
        console.log(item)
      },

      runClick(data) {
        console.log(this.vendor);
      }
    }
  }
</script>
<style></style>
