<template>
  <div>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a href="#">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Inventory</li>
      </ol>
    </nav>

    <template>
      <div class="row">
        <div class="col shortcard">
          <card type="tasks" v-if="inv_data">
            <template slot="header">
              <h3 class="title d-inline">Your Inventory</h3>
              <p class="card-category d-inline">Unsorted</p>
              <form class="form-inline ml-auto s-line" @submit.prevent="onSubmit">
                <base-input class="no-border s-line"
                            v-model="search"
                            placeholder="Search"/>
                <base-button type="link" icon round native-type="submit">
                  <i class="s-line tim-icons icon-zoom-split"></i>
                </base-button>
              </form>
              <template v-if="tags" v-bind="tags">
                <el-select multiple
                    class="select-primary s-line"
                    collapse-tags
                    v-model="set_tags"
                    placeholder="Tags">
                    <el-option v-for="tag in tags"
                      class="select-primary"
                      :value="tag"
                      :label="tag"
                      :key="tag">
                    </el-option>
                </el-select>
              </template>
            </template>
            <div class="table-full-width table-responsive">
              <template>
                <base-table v-if="fullData" :data="fullData.data"
                            :columns="fullData.columns"
                            :actions="fullData.actions" @tbc="runClick">
                  <template slot="columns">
                  </template>  
                  <template slot-scope="{row}">
                  </template>    
                </base-table>

                <div v-else class="col">
                  <card>
                    No results...
                  </card>
                </div>
              </template>
            </div>
          </card>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
  import {Select, Option} from 'element-ui'
  import { BaseTable } from '@/components';
  import config from '@/config';
  import axios from 'axios';
  import dash from '@/plugins/dashboard.mixin';

  export default {
    mixins: [dash],
    components: {
      BaseTable,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        inv_data: [],
        fullData: false,
        itypes: {
          0:  'Texture',
          1:  'Sound',
          3:  'Landmark',
          5:  'Clothing',
          6:  'Object',
          7:  'Notecard',
          10: 'Script',
          13: 'Bodypart',
          20: 'Animation',
          21: 'Gesture',
          56: 'Setting'
        },
        ptypes: {
          '1': 'C',
          '2': 'T',
          '4': 'CM',
          '5': 'CT',
          '6': 'MT',
          '7': 'CMT'
        }
      }
    },
    mounted() {
      this.fetchData();
    },
    created() {
      this.search = this.$store.state.last_search['inventory']
    },
    watch: {
      inv_data: {
        handler(inv_data) {
          let out = {
            'actions': true,
            'columns': {
              key: 'Item Name',
              doc_count: 'Copies',
              item_type: 'Item Type',
              perms: 'Current/Next Perms',
              exists: 'Available',
              sold: 'Sold Out'
            },
            data: inv_data
          }
          if (inv_data.length > 0) {
            this.fullData = out
          } else {
            this.fullData = false
          }
          
        }
      },
      set_tags: {
        handler(set_tags) { this.queueSearch(); }
      },
      search: {
        handler(search) { this.queueSearch(); }
      }
    },
    methods: {
      fetchSearch() {
        if (!this.isReady()) return;
        console.log(`Tags: ${this.set_tags}`);
        console.log(`String: ${this.search}`);
        this.fetchData();
      },

      mapPerms(perms) {
        let set = []
        perms = perms.toString();
        for (let perm of perms) {
          set.push(this.ptypes[perm]);
        }
        return set;
      },

      fetchData() {
        if (!this.isLoggedIn()) return;
        axios.post(`${this.api_url}/v2/web/account/inventory`, { 
          slug: this.search,
          source: true, 
          size: 25
        }, {
          withCredentials: true
        }).then(response => {
          this.$store.commit('setSearch', {'inventory': this.search})
          if (response.data) this.inv_data = response.data.map(item => {
            if (item.item_type) item.item_type = this.itypes[item.item_type];
            if (item.perms) item.perms = this.mapPerms(item.perms).join('/');
            if (item.exists) item.exists = 'Yes'; else item.exists = 'No';
            if (item.sold) item.sold = 'Yes'; else item.sold = 'No';
            return item;
          })
          this.tags = ['tag1', 'tag2', 'tag3', 'tag4']; //Fetch tags for real in the future
        })

      },
      jabber(input) {
        console.log(input);
      },
      runClick(data) {
        console.log("Parent Click: " + data);
      }
    }
  }
</script>
<style></style>
