export default {
  data() {
    return {
      ready: false,

      search: '', 
      search_timer: null,
      
      tags: [],
      set_tags: []
    }
  },
  created() {
  },
  methods: {
    mapName(name) {
      let parts = name.split(".")
      if (parts[1] == 'resident') parts = [parts[0]]
      return parts.join(' ')
    },
    isLoggedIn() {
      if (!this.$store.state.logged_in) {
        this.$store.commit('url', location.pathname);
        this.$router.push({ name: 'login' });
        return false;
      }
      return true;
    },
    isReady() {
      if (!this.ready) {this.ready = true; return false}
      return this.ready;
    },
    queueSearch() {
      clearTimeout(this.search_timer);
      this.search_timer = setTimeout(this.fetchSearch, 1000)
    }
  }
}