<template>
  <div>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a href="#">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Transactions</li>
      </ol>
    </nav>

    <template>
      <div class="row">
        <div class="col" v-for="card in statsCards" :key="card.subTitle">
          <stats-card
            :title="card.title"
            :sub-title="card.subTitle"
            :type="card.type"
            :icon="card.icon"
          ></stats-card>
        </div>
      </div>
      <div class="row">
        <div class="col shortcard">
          <card type="tasks" v-if="inv_data">
            <template slot="header">
              <h3 class="title d-inline">Transactions</h3>
              <p class="card-category d-inline">{{ sort }}</p>
              <form class="form-inline ml-auto s-line" @submit.prevent="onSubmit">
                <base-input class="no-border s-line"
                            v-model="search"
                            placeholder="User"/>
                <base-button type="link" icon round native-type="submit">
                  <i class="s-line tim-icons icon-zoom-split"></i>
                </base-button>
              </form> 
              <template v-if="tags" v-bind="tags">
                <el-select multiple
                    class="select-primary s-line"
                    collapse-tags
                    v-model="set_tags"
                    placeholder="Region">
                    <el-option v-for="tag in tags"
                      class="select-primary"
                      :value="tag"
                      :label="tag"
                      :key="tag">
                    </el-option>
                </el-select>
              </template>
              <template v-if="types" v-bind="types">
                <el-select multiple
                    class="select-primary s-line"
                    collapse-tags
                    v-model="set_types"
                    placeholder="Transaction Type">
                    <el-option v-for="type in types"
                      class="select-primary"
                      :value="type"
                      :label="type"
                      :key="type">
                    </el-option>
                </el-select>
              </template>
            </template>
            <div class="table-full-width table-responsive">
              <template>
                <base-table v-if="fullData" :data="fullData.data"
                            :columns="fullData.columns" :actionData="fullData.actionData"
                            :actions="fullData.actions" @tbc="runClick" class="sales-table">
                  <template slot="columns">
                  </template>  
                  <template slot-scope="{row}">
                  </template>    
                </base-table>

                <div v-else class="col">
                  <card>
                    No results...
                  </card>
                </div>
              </template>
            </div>
          </card>
        </div>
      </div>
      <modal :show.sync="modals.vendor" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
        <vendor-details v-model="modals.vendor"></vendor-details>
      </modal>
    </template>

  </div>
</template>

<script>
  import StatsCard from 'src/components/Cards/StatsCard';
  import VendorDetails from '@/pages/Dash/Detail/Vendor';
  import {Select, Option} from 'element-ui';
  import { BaseTable, Modal } from '@/components';
  import config from '@/config';
  import axios from 'axios';
  import dash from '@/plugins/dashboard.mixin';

  export default {
    mixins: [dash],
    components: {
      BaseTable,
      StatsCard,
      Modal,
      VendorDetails,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        types: ['purchase', 'refund', 'affiliate', 'fee', 'cut'],
        set_types: [],
        inv_data: [],
        fullData: false,
        sort: 'Newest',
        statsCards: false,
        modals: {
          vendor: false
        }
      }
    },
    mounted() {
      this.fetchData();
    },
    created() {
      this.search = this.$store.state.last_search['transactions']
    },
    watch: {
      inv_data: {
        handler(inv_data) {
          let out = {
            'actions': true,
            'columns': {
              'type': 'Type',
              'payee_name': 'User Involved',
              'amount': 'Amount',
              'time': 'Date',
              'region': 'Region',
              'status': 'Done?',
              'reason': 'Reason'
            },
            data: inv_data.map(item => {
              item.time = new Date(item.time*1000).toLocaleString();
              item.payee_name = this.mapName(item.payee_name);
              if(item.status) item.status = 'Yes'; else item.status = 'No'
              return item
            }),
            'actionData': {
              'Vendor Info': {
                key: 'processor_id',
                icon: 'icon-basket-simple',
                type: 'Success'
              },
              'Payment Info': {
                key: 'id',
                icon: 'icon-spaceship',
                type: 'Success'
              }
            }
          }
          if (inv_data.length > 0) {
            this.fullData = out
          } else {
            this.fullData = false
          }
          
        }
      },
      set_types: {
        handler(set_types) { this.queueSearch(); }
      },
      set_tags: {
        handler(set_tags) { this.queueSearch(); }
      },
      search: {
        handler(search) { this.queueSearch(); }
      }
    },
    methods: {
      fetchSearch() {
        if (!this.isReady()) return;
        if (this.tags.length != 0) {
          if (this.set_tags.length == this.tags.length) this.set_tags = [];
        }
        this.fetchData();
      },

      fetchData() {
        if (!this.isLoggedIn()) return;
        axios.post(`${this.api_url}/v2/web/account/sales`, {
          tags: this.set_tags,
          type: this.set_types,
          name: this.search,
          x: 'transactions'
        }, {
          withCredentials: true
        }).then(response => {
          let info = response.data;
          
          this.$store.commit('setSearch', {'transactions': this.search})
          if (info) this.inv_data = info;
        })

        axios.post(`${this.api_url}/v2/web/account/sales/income`, {
          tags: this.set_tags,
          type: this.set_types
        }, {
          withCredentials: true
        }).then (response => {
          this.statsCards = response.data.map(item => {
            item.title = `$${item.title}L`
            return item;
          });
        })

        if(this.tags.length == 0) {
          axios.get(`${this.api_url}/v2/web/account/vendors/regions`, {
            withCredentials: true
          }).then(response => {
            if (response.data) this.tags = response.data;
          })
        }

      },
      runClick(data) {
        const context = data[2];
        const button = data[0];
        const id = data[1];

        if (button == 'Payment Info') {
          if (context == 'purchase') {
            this.$router.push({ name: 'purchase', params: {id: data[1]} });
          }
          else {
            window.open('https://accounts.secondlife.com/transaction_history/', '_blank');
          }

        }
        if (button == 'Vendor Info') {
          this.$store.commit('setModal', data[1]);
          this.modals.vendor = true;
          history.pushState(
            {id: data[1], prev: window.location.href, title: this.$route.meta.title}, 
            'Vendor Details', 
            `/admin/vendors/${data[1]}`
          )
          //this.$router.push({ name: 'vendor_detail', params: {id: data[1]}})
        }
        console.log("Parent Click: " + data);
      }
    }
  }
</script>
<style></style>
