<template>
  <div>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Home</li>
      </ol>
    </nav>
    <template>
      <div v-if="graph_data" class="row">
        <div class="col-lg-6">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">This Weeks Sales</h5>
              <h3 class="card-title">
                <div class="row">
                  <div class="col">By Day</div>
                  <div class="col-4 align-items-end" style="text-align: right">
                    <i class="tim-icons icon-coins text-primary "></i>892
                    <i style="padding-left: 8px" class="tim-icons icon-cart text-primary "></i>447
                  </div>
                </div>
              </h3>
            </template>
            <line-chart style=""
              :chart-data="purpleLineChart.chartData"
              :gradient-color="purpleLineChart.gradientColors"
              :gradient-stops="purpleLineChart.gradientStops"
              :extra-options="purpleLineChart.extraOptions">
            </line-chart>
          </card>
        </div>
        <div class="col-lg-6">
          <card type="chart">
            <template slot="header">
              <h5 class="card-category">This Weeks Sales</h5>
              <h3 class="card-title">
                By Region
              </h3>
            </template>
            <bar-chart style=""
              :chart-data="blueBarChart.chartData"
              :gradient-color="blueBarChart.gradientColors"
              :gradient-stops="blueBarChart.gradientStops"
              :extra-options="blueBarChart.extraOptions">
            </bar-chart>
          </card>
        </div>
      </div>
      <div v-else class="row">
        <div class="col">
          <card>
            Loading graphs...
          </card>
        </div>
      </div>
    </template>
    <template>
      <div class="row">
        <div class="col shortcard">
          <card type="tasks">
            <template slot="header">
              <h6 class="title d-inline">Recent Inventory</h6>
              <p class="card-category d-inline">Newest</p>
              <base-dropdown menu-on-right=""
                             tag="div"
                             title-classes="btn btn-link btn-icon"
                             aria-label="Settings menu">
                <i slot="title" class="tim-icons icon-components"></i>
                <a class="dropdown-item" href="#pablo">Sales</a>
                <a class="dropdown-item" href="#pablo">Newest</a>
                <a class="dropdown-item" href="#pablo">Profit</a>
              </base-dropdown>
            </template>
            <div class="table-full-width table-responsive">
              <inventory v-bind:fullData="fullData"></inventory>
            </div>
          </card>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';
  import Inventory from '@/pages/Tables/Inventory';
  import * as chartConfigs from '@/components/Charts/config';
  import config from '@/config';
  import axios from 'axios';

  export default {
    components: {
      LineChart,
      BarChart,
      Inventory,
    },
    data() {
      return {
        info: false,
        graph_data: false,
        inv_data: false,
        purpleLineChart: {
          extraOptions: {
            maintainAspectRatio: false,
              legend: {
                display: false
              },
            responsive: true,
            tooltips: {
              backgroundColor: '#f5f5f5',
              titleFontColor: '#333',
              bodyFontColor: '#666',
              bodySpacing: 4,
              xPadding: 12,
              mode: "nearest",
              intersect: 0,
              position: "nearest"
            },
            scales: {
              yAxes: [
                {
                  id: 'sales',
                  position: "left",
                  gridLines: {
                    display: false,
                    drawBorder: false,
                    color: 'rgba(29,140,248,0.1)',
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    suggestedMin: 50,
                    suggestedMax: 110,
                    padding: 5,
                    fontColor: "#ff8a76"
                  }
                },
                {
                  id: 'linden',
                  position: "right",
                  gridLines: {
                    display: false,
                    drawBorder: false,
                    color: 'rgba(29,140,248,0.1)',
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    suggestedMin: 50,
                    suggestedMax: 110,
                    padding: 5,
                    fontColor: "#ff8a76"
                  }
                }
              ],
          
              xAxes: [{
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(220,53,69,0.1)',
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 20,
                  fontColor: "#ff8a76"
                }
              }]
            }
          },
          chartData: {},
        },
        blueBarChart: {
          extraOptions: {
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            responsive: true,
            tooltips: {
              backgroundColor: '#f5f5f5',
              titleFontColor: '#333',
              bodyFontColor: '#666',
              bodySpacing: 4,
              xPadding: 12,
              mode: "nearest",
              intersect: 0,
              position: "nearest"
            },
            scales: {
              yAxes: [{
          
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.1)',
                  zeroLineColor: "transparent",
                },
                ticks: {
                  suggestedMin: 60,
                  suggestedMax: 120,
                  padding: 20,
                  fontColor: "#9e9e9e"
                }
              }],
              xAxes: [{
          
                gridLines: {
                  drawBorder: false,
                  color: 'rgba(29,140,248,0.1)',
                  zeroLineColor: "transparent",
                },
                ticks: {
                  padding: 20,
                  fontColor: "#9e9e9e"
                }
              }]
            }
          },
          chartData: {},
          gradientColors: ['rgba(29,140,248,0.2)', 'rgba(29,140,248,0.0)', 'rgba(29,140,248,0)'],
          gradientStops: [1, 0.4, 0],
        },
      }
    },
    mounted() {
    },
    created() {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData()
    },
    methods: {
      fetchData () {
        this.error = this.post = null

        this.fullData = {
          'actions': true,
          'actionKey': 'name',
          'columns': {
            "id": "#", 
            "name": "Item Name", 
            "region": "Current Sim", 
            "inventory": "In Stock", 
            "sold": "Sales", 
            "income": "Income"
          },
          'data': [
            {
              id: 1,
              name: "Test Notecard",
              sold: 1,
              region: "Seclusion",
              inventory: 1,
              income: "$84L",
            },
            {
              id: 2,
              name: "Test Box",
              sold: 5,
              region: "Seclusion",
              inventory: 22,
              income: "$666L",
            },
            {
              id: 3,
              name: "Test Meme",
              sold: 420,
              region: "Seclusion",
              inventory: 1,
              income: "$8774L",
            },
            {
              id: 4,
              name: "Test Sound",
              sold: 12,
              region: "Seclusion",
              inventory: 1,
              income: "$1230L",
            },
          ]
        }

        this.purpleLineChart.chartData = {
          labels: ['MON', 'TUES', 'WED', 'THURS', 'FRI', 'SAT'],
          datasets: [{
            axisID: 'linden',
            label: "Linden",
            fill: true,
            borderColor: '#d048b6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#d048b6',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#d048b6',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [80, 100, 70, 80, 140, 80],
          },
          {
            axisID: 'sales',
            label: "Sales",
            fill: true,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: '#1f8ef1',
            pointBorderColor: 'rgba(255,255,255,0)',
            pointHoverBackgroundColor: '#1f8ef1',
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: [40, 22, 12, 32, 55, 70],
          }]
        }

        this.blueBarChart.chartData = {
          labels: ['Seclusion', 'Abnormality', 'Uber', 'GG', 'Kinky', 'FaMeshed'],
          datasets: [{
            label: "Linden",
            fill: true,
            borderColor: '#d048b6',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: [53, 20, 10, 80, 140, 45],
          },
          {
            label: "Sales",
            fill: true,
            borderColor: '#1f8ef1',
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: [13, 8, 2, 18, 40, 32],
          }]
        }

        const fetchedId = this.$route.params.id
        // replace `getPost` with your data fetching util / API wrapper
        this.graph_data = true;
      }
    }
  };
</script>
<style></style>
