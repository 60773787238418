<template>
  <div>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a href="#">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Warehouses</li>
      </ol>
    </nav>

    <template>
      <div class="row">
        <div class="col shortcard">
          <card type="tasks" v-if="inv_data">
            <template slot="header">
              <h3 class="title d-inline">Warehouses</h3>
              <p class="card-category d-inline">{{ sort }}</p>
              <form class="form-inline ml-auto s-line" @submit.prevent="onSubmit">
                <base-input class="no-border s-line"
                            v-model="search"
                            placeholder="Warehouse or Parcel"/>
                <base-button type="link" icon round native-type="submit">
                  <i class="s-line tim-icons icon-zoom-split"></i>
                </base-button>
              </form>
              <template v-if="tags" v-bind="tags">
                <el-select multiple
                    class="select-primary s-line"
                    collapse-tags
                    v-model="set_tags"
                    placeholder="Region">
                    <el-option v-for="tag in tags"
                      class="select-primary"
                      :value="tag"
                      :label="tag"
                      :key="tag">
                    </el-option>
                </el-select>
              </template>
            </template>
            <div class="table-full-width table-responsive">
              <template>
                <base-table v-if="fullData" :data="fullData.data"
                            :columns="fullData.columns" :actionData="fullData.actionData"
                            :actions="fullData.actions" @tbc="runClick" class="sales-table">
                  <template slot="columns">
                  </template>  
                  <template slot-scope="{row}">
                  </template>    
                </base-table>

                <div v-else class="col">
                  <card>
                    No results...
                  </card>
                </div>
              </template>
            </div>
          </card>
        </div>
      </div>
      <modal :show.sync="modals.vendor" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
        <vendor-details v-model="modals.vendor"></vendor-details>
      </modal>
    </template>

  </div>
</template>

<script>
  import StatsCard from 'src/components/Cards/StatsCard';
  import VendorDetails from '@/pages/Dash/Detail/Vendor';
  import {Select, Option} from 'element-ui';
  import { BaseTable, Modal } from '@/components';
  import config from '@/config';
  import axios from 'axios';
  import dash from '@/plugins/dashboard.mixin';

  export default {
    mixins: [dash],
    components: {
      BaseTable,
      StatsCard,
      Modal,
      VendorDetails,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        inv_data: [],
        fullData: false,
        ready: false,
        sort: 'Newest',
        statsCards: false,
        modals: {
          vendor: false
        }
      }
    },
    mounted() {
      this.fetchData();
    },
    created() {
      this.search = this.$store.state.last_search['warehouses']
    },
    watch: {
      inv_data: {
        handler(inv_data) {
          let out = {
            'actions': true,
            'columns': {
              name: 'Vendor Name',
              region: 'Region',
              parcel: 'Parcel',
              status: 'Status',
              items: 'Inventory Size'
            },
            data: inv_data.map(item => {
              if (item.online) item.status = 'Online'; else item.status = 'Offline'
              return item
            }),
            'actionData': {
              'Vendor Info': {
                key: '_id',
                icon: 'icon-basket-simple',
                type: 'Success'
              }
            }
          }
          if (inv_data.length > 0) {
            this.fullData = out
          } else {
            this.fullData = false
          }
          
        }
      },
      set_tags: {
        handler(set_tags) { this.queueSearch(); }
      },
      search: {
        handler(search) { this.queueSearch(); }
      }
    },
    methods: {
      fetchSearch() {
        if (!this.isReady()) return;
        if (this.tags.length != 0) {
          if (this.set_tags.length == this.tags.length) this.set_tags = [];
        }
        this.fetchData();
      },

      fetchData() {
        if (!this.isLoggedIn()) return;
        axios.post(`${this.api_url}/v2/web/account/warehouses`, {
          name: this.search,
          parcel: this.search,
          tags: this.set_tags
        }, {
          withCredentials: true
        }).then(response => {        
          this.$store.commit('setSearch', {'warehouses': this.search})
          if (response.data) this.inv_data = response.data;
        })

        if(this.tags.length == 0) {
          axios.get(`${this.api_url}/v2/web/account/warehouses/regions`, {
            withCredentials: true
          }).then(response => {        
            if (response.data) this.tags = response.data;
          })
        }
      },
      runClick(data) {
        const context = data[2];
        const button = data[0];
        const id = data[1];

        if (button == 'Vendor Info') {
          this.$store.commit('setModal', data[1]);
          this.modals.vendor = true;
          history.pushState(
            {id: data[1], prev: window.location.href, title: this.$route.meta.title}, 
            'Vendor Details', 
            `/admin/vendors/${data[1]}`
          )
          //this.$router.push({ name: 'vendor_detail', params: {id: data[1]}})
        }
        console.log("Parent Click: " + data);
      }
    }
  }
</script>
<style></style>