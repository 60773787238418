
<template>
  <base-table v-if="fullData" :data="fullData.data"
              :columns="fullData.columns"
              :actions="fullData.actions" :actionKey="fullData.actionKey" @tbc="runClick">
    <template slot="columns">
    </template>  
    <template slot-scope="{row}">
    </template>    
  </base-table>
</template>

<script>
  import { BaseTable } from "@/components";
  export default {
    components: {
      BaseTable
    },
    methods: {
      runClick(data) {
      }
    },
    props: ['fullData'],
    data() {
      return {}
    }
  }
</script>