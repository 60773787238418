<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{column}}</th>
          <th v-if="actions" class="text-right">Actions</th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td v-for="(column, index) in columns"
              :key="index"
              v-if="hasValue(item, index)">
            {{itemValue(item, index)}}
          </td>
          <td v-if="actions" class="td-actions text-right">
            <el-tooltip v-for="(action, name) in actionData" :key="name" 
                :content="name" :open-delay="300" placement="top">
              <base-button :type="action.type"  @click.native="runClick(name, item)" 
                  class="text-right" size="sm" icon style="margin-left: 5px!important">
                <i class="tim-icons" :class="action.icon"></i>
              </base-button>
            </el-tooltip>
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
  export default {
    name: 'base-table',
    props: {
      columns: {
        type: Object,
        default: () => {},
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      },
      actions: false,
      actionKey: false,
      actionData: {
        type: Object, 
        default: () => {
          return {
            'Information': {  
              key: false,
              icon: 'icon-single-02',
              type: 'info'
            },
            'Convert': {
              key: false,
              icon: 'icon-spaceship',
              type: 'Success'
            }
          }
        }
      }
    },
    computed: {
      tableClass() {
        return this.type && `table-${this.type}`;
      }
    },
    methods: {
      hasValue(item, column) {
        return item[column] !== "undefined";
      },
      itemValue(item, column) {
        return item[column];
      },
      runClick(button, item) {
        let base = Object.values(item)[0];
        let key = base;

        if (this.actionData[button].key) key = item[this.actionData[button].key];
        this.$emit('tbc', [button, key, base]);
      }
    }
  };
</script>
<style>
</style>
