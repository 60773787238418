<template>
  <div>
    <nav aria-label="breadcrumb" role="navigation">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page"><a href="#">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Inventory</li>
      </ol>
    </nav>

    <template>
      <div class="row">
        <div class="col shortcard">
          <card type="tasks" v-if="inv_data">
            <template slot="header">
              <h3 class="title d-inline">Your Inventory</h3>
              <p class="card-category d-inline">Unsorted</p>
              <form class="form-inline ml-auto s-line" @submit.prevent="onSubmit">
                <base-input class="no-border s-line"
                            v-model="search"
                            placeholder="Search"/>
                <base-button type="link" icon round native-type="submit">
                  <i class="s-line tim-icons icon-zoom-split"></i>
                </base-button>
              </form>
              <template v-if="tags" v-bind="tags">
                <el-select multiple
                    class="select-primary s-line"
                    collapse-tags
                    v-model="set_tags"
                    placeholder="Tags">
                    <el-option v-for="tag in tags"
                      class="select-primary"
                      :value="tag"
                      :label="tag"
                      :key="tag">
                    </el-option>
                </el-select>
              </template>
            </template>
            <div class="table-full-width table-responsive">
              <template>
                <base-table v-if="fullData" :data="fullData.data"
                            :columns="fullData.columns"
                            :actions="fullData.actions" @tbc="runClick">
                  <template slot="columns">
                  </template>  
                  <template slot-scope="{row}">
                  </template>    
                </base-table>

                <div v-else class="col">
                  <card>
                    No results...
                  </card>
                </div>
              </template>
            </div>
          </card>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
  import {Select, Option} from 'element-ui'
  import { BaseTable } from '@/components';
  import config from '@/config';
  import axios from 'axios';
  import dash from '@/plugins/dashboard.mixin';

  export default {
    mixins: [dash],
    components: {
      BaseTable,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        inv_data: [],
        fullData: false,
      }
    },
    mounted() {
      this.fetchData();
    },
    created() {
      this.search = this.$store.state.last_search['inventory']
    },
    watch: {
      inv_data: {
        handler(inv_data) {
          let out = {
            'actions': true,
            'columns': {
              'key': 'Item Name',
              'doc_count': 'Locations Available / Copies Present'
            },
            data: inv_data
          }
          if (inv_data.length > 0) {
            this.fullData = out
          } else {
            this.fullData = false
          }
          
        }
      },
      set_tags: {
        handler(set_tags) { this.queueSearch(); }
      },
      search: {
        handler(search) { this.queueSearch(); }
      }
    },
    methods: {
      fetchSearch() {
        if (!this.isReady()) return;
        console.log(`Tags: ${this.set_tags}`);
        console.log(`String: ${this.search}`);
        this.fetchData();
      },
      fetchData() {
        if (!this.isLoggedIn()) return;
        if (!this.$store.state.logged_in) return;
        axios.post(`${this.api_url}/v2/web/account/inventory`, {
          owner_id: this.$store.state.u_id, 
          slug: this.search
        }, {
          withCredentials: true
        }).then(response => {
          let info = response.data;
          
          this.$store.commit('setSearch', {'inventory': this.search})
          if (info) this.inv_data = info;
          this.tags = ['tag1', 'tag2', 'tag3', 'tag4']; //Fetch tags for real in the future
        })

      },
      jabber(input) {
        console.log(input);
      },
      runClick(data) {
        console.log("Parent Click: " + data);
      }
    }
  }
</script>
<style></style>
